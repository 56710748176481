import {React, useState, useContext} from 'react';
import {Grid, Typography} from '@mui/material';

export default function Products() {

    return (
        <Grid container>
            <Grid item>
                <Typography>Page in development!</Typography>
            </Grid>
        </Grid>
    )

}